import React from 'react'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider'
import Snackbar from '@mui/material/Snackbar'

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useActionsAPI from 'components/utils/actionsAPI'

const CardMenu = ({
  contextMenu,
  handleMenuClose,
  card,
  primaryAction = null,
  primaryActionText = null,
  showButton = true,
  showEventMenu = false,
  showPlaceMenu = true,
  ...props
}) => {

  const { patchEvent } = useActionsAPI()

  const [showMessage, setShowMessage] = React.useState(false)
  const [message, setMessage] = React.useState('Shared and copied to clipboard!')
  const setAndShowMessage = (message) => {
    setShowMessage(true)
    setMessage(message ? message : 'Success!')
  }

  const copyID = (event) => {
    event.preventDefault()
    event.stopPropagation()
    navigator.clipboard.writeText(card.id)
    handleMenuClose()
  }

  const copyName = (event) => {
    event.preventDefault()
    event.stopPropagation()
    navigator.clipboard.writeText(card?.title)
    handleMenuClose()
    setAndShowMessage('Copied to clipboard!')
  }

  const is_featured = card?.is_featured

  const setFeatured = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const data = { is_featured: !is_featured }
    patchEvent(eventID, data)
    handleMenuClose()
    setAndShowMessage('Event featured')
  }

  const setApproved = (event, is_approved = false) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const data = { is_approved: is_approved }
    patchEvent(eventID, data)
    handleMenuClose()
    setAndShowMessage('Event hidden')
  }


  const editEvent = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const eventID = card?.id
    const slug = card?.properties?.slug
    const pageLink = `/edit-event?id=${slug ? slug : eventID}`
    window.open(pageLink, '_blank')
    handleMenuClose()
  }

  const copyLink = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const slug = card?.properties?.slug
    const cardID = card?.id

    const pageLink = `/places/details/${slug ? slug : cardID}`
    navigator.clipboard.writeText(`${window.location.origin}${pageLink}`)
    handleMenuClose()
    setAndShowMessage('Link copied to clipboard!')
  }

  const actionLookupTable = {
    approve: setApproved,
    copyLink: copyLink,
  };

  const handlePrimaryAction = (event, actionKey) => {
    const action = actionLookupTable[actionKey];
    if (action) {
      action(event);
      debugger
    } else {
      console.error('Invalid action key:', actionKey);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = anchorEl ? true : false
  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  }

  const handleClose = (event) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(null);
  }

  const menuStyle = {
    position: 'absolute',
    right: 6,
    bottom: 50,
    zIndex: 100,
    backgroundColor: 'white',
  }

  const menuPosition = contextMenu !== null
    ? {
      top: contextMenu.mouseY,
      left: contextMenu.mouseX
    }
    : anchorEl
      ? {
        top: anchorEl.getBoundingClientRect().top + 10,
        left: anchorEl.getBoundingClientRect().left
      }
      : undefined


  if (anchorEl) console.log('menuPosition ', menuPosition, anchorEl);

  return (
    <div style={menuStyle}>
      <Snackbar
        autoHideDuration={6000}
        open={showMessage}
        message={message}
        sx={{ bottom: 80 }} />

      {primaryAction
        ? <a
            className="button secondary small"
            onClick={(event) => handlePrimaryAction(event, primaryAction)}
          >
            {primaryActionText}
          </a>
        : null
      }

      {showButton
        ? (
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            style={{ border: '1px #DDD solid' }}
            onClick={handleClick}
            >
            <MoreVertIcon />
          </IconButton>
        )
        : null
      }

      <Menu
        id="long-menu"
        anchorPosition={menuPosition}
        anchorEl={anchorEl}
        open={open || contextMenu !== null}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'long-button', }}
        anchorReference="anchorPosition" >

        {showEventMenu ? (
          <MenuList>
            <MenuItem onClick={setFeatured}>{is_featured ? 'Don\'t Feature Event' : 'Feature Event'}</MenuItem>
            <MenuItem onClick={setApproved}>Hide Event</MenuItem>
            <MenuItem onClick={editEvent}>Edit Event</MenuItem>
            <Divider />
            <MenuItem onClick={copyName}>Copy Event Name</MenuItem>
            <MenuItem onClick={copyID}>Copy Event ID</MenuItem>
            <MenuItem onClick={copyLink}>Copy Event Link</MenuItem>
          </MenuList>
        ) : null}

        {showPlaceMenu
          ? <MenuList>
            <MenuItem onClick={copyName}>Copy Place Name</MenuItem>
            <MenuItem onClick={copyID}>Copy Place ID</MenuItem>
            <MenuItem onClick={copyLink}>Copy Place Link</MenuItem>
          </MenuList>
          : null
        }

      </Menu>
    </div>
  )
}

export default CardMenu