import { useState } from 'react';
import axios from 'axios'

import { userStore } from 'state/store-zustand'

const useActionsAPI = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const token = userStore((state) => state.token)

    const isDev = false
    const domain = isDev
        ? 'http://localhost:900/v0.3/'
        : 'https://api.vibemap.com/v0.3/'


        const login = async (username, password) => {
        setIsLoading(true);
        setError(null);

        let data = JSON.stringify({
            "email": username,
            "password": password
        });

        const useDjango = true
        const endpoint = useDjango
            ? 'https://api.vibemap.com/v0.3/auth/login/'
            : 'https://api.vibemap.com/v0.7/auth/login/'


        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: endpoint,
            headers: { 'Content-Type': 'application/json' },
            data: data
        };

        try {
            const response = await axios(config);
            console.log('SUCCESS: login', response);
            localStorage.setItem('token', response.data.token);
            setIsLoggedIn(true);
            return response.data;
        } catch (error) {
            console.error('ERROR: login', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };


    const patchEvent = async (id, data) => {
        setIsLoading(true);
        setError(null);


        const endpoint = 'https://api.vibemap.com/v0.3/events/' + id + '/'

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: endpoint,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            data: data
        };

        try {
            const response = await axios(config);
            console.log('SUCCESS: patchEvent', response);
            return response;
        } catch (error) {
            console.error('ERROR: patchEvent', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }


    const patchPlace = async (id, data, is_dev = false) => {
        setIsLoading(true);
        setError(null);

        const domain = is_dev ? 'http://localhost:9000/v0.3' : 'https://api.vibemap.com/v0.3'
        const endpoint = `${domain}/places/${id}`
        debugger

        let config = {
            method: 'patch',
            maxBodyLength: Infinity,
            url: endpoint,
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            data: data
        };

        try {
            const response = await axios(config);
            console.log('SUCCESS: patchPlace', response);
            return response;
        } catch (error) {
            console.error('ERROR: patchPlace', error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }


    // Export the hook
    return {
        isLoading,
        error,
        patchEvent,
        patchPlace,
    }
};

// TODO: Remove this is in zustand now
export const getToken = () => {
    const token = localStorage.getItem('token') !== null;

    // Check if token is still valid or refresh it:

    return token;
};

export default useActionsAPI;